<template>
    <Page title="Dashboard" icon="mdi-view-dashboard" class-extra="mx-auto"
    ></Page>
</template>
<script>
export default {
    data() {
        return {};
    },
};
</script>
    <script>
        export default {
            components: {}
        }
    </script>
